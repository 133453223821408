import "./App.css";
import TestImage from "./images/bird.png";
import React, { useState, useCallback, useRef } from "react";
import PartTwo from "./components/PartTwo.js";
import ImageFilter from "react-image-filter";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { FaRegCaretSquareUp, FaRegCaretSquareDown } from "react-icons/fa";
import { ChromePicker } from "react-color";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import html2canvas from "html2canvas";

function App() {
  const imageRef = useRef(null);
  const [filter, setFilter] = useState();
  const filters = ["none", "grayscale", "sepia", "duotone", "invert"];

  const [crop, setCrop] = useState({
    x: 10,
    y: 10,
    width: 50,
    height: 50,
    unit: "%",
  });
  // const [image] = useState("./images/test.jpg"); // Change this path
  const [completedCrop, setCompletedCrop] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const mergedImageRef = useRef(null);
  const mergedCanvasRef = useRef(null);
  const ZOOM_STEP = 1;
  const [imageZoomLevel, setImageZoomLevel] = useState(100);
  const [brightnessValue, setBrightnessValue] = useState(100);
  const [grayscaleValue, setGreyScaleValue] = useState(0);
  const [sepiaValue, setSepiaValue] = useState(0);
  const [contrastValue, setContrastValue] = useState(100);
  const [invertValue, setInvertValue] = useState(0);
  const [opacityValue, setOpacityValue] = useState(100);
  const [saturateValue, setSaturateValue] = useState(100);
  const [blurValue, setBlurValue] = useState(0);
  const [zoomedCroppedImageUrl, setZoomedCroppedImageUrl] = useState(null);
  // const [image, setImage] = useState("./images/test.jpg");
  const [colorAdjustments, setColorAdjustments] = useState({
    red: 0,
    green: 0,
    blue: 0,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [manipulatedImage, setManipulatedImage] = useState(null);

  const [colorFilter, setColorFilter] = useState({
    brightness: 0,
    contrast: 0,
    greyscale: 0,
    invert: 0,
    sepia: 0,
    diether: 0,
  });

  const [rotationValue, setRotationValue] = useState(0);
  const [originalImage, setOriginalImage] = useState(null);

  const [downloadOptionsVisible, setDownloadOptionsVisible] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState("jpg");

  const [appliedFilters, setAppliedFilters] = useState([]);
  const [jpgQualityBox, setJpgQualityBox] = useState(false);
  const [qualityValue, setQualityValue] = useState(0);
  const [color, setColor] = useState("#FF5733");
  const [clickCoordinates, setClickCoordinates] = useState({
    x: null,
    y: null,
  });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showColorPickerForImage, setShowColorPickerForImage] = useState(false);
  const [hexColorValue, setHexColorValue] = useState(null);
  const [hexColor, setHexColor] = useState("#FF5733");

  const [croppedImage, setCroppedImage] = useState(null);

  const apiKey = process.env.REACT_APP_API;

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      setManipulatedImage(null);
    }
  };

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    const zoomFactor = imageZoomLevel / 100;

    const zoomedCrop = {
      x: croppedArea.x / zoomFactor,
      y: croppedArea.y / zoomFactor,
      width: croppedArea.width / zoomFactor,
      height: croppedArea.height / zoomFactor,
      unit: "px",
    };

    setCompletedCrop(zoomedCrop);
    makeClientCrop(zoomedCrop);
  };

  const makeClientCrop = async (crop) => {
    if (manipulatedImage && crop.width && crop.height) {
      const zoomedCrop = {
        x: crop.x * (100 / imageZoomLevel),
        y: crop.y * (100 / imageZoomLevel),
        width: crop.width * (100 / imageZoomLevel),
        height: crop.height * (100 / imageZoomLevel),
        unit: "px",
      };

      const croppedImage = await getCroppedImg(manipulatedImage, zoomedCrop);
      console.log("Cropped image url is >>", croppedImage);
      setCroppedImage(croppedImage);
    }
  };

  const onCropClick = async () => {
    const croppedImage = await getCroppedImg(manipulatedImage, crop);
    console.log("Cropped image url is >>", croppedImage);
    setCroppedImage(croppedImage);
  };

  const getCroppedImg = (imageSrc, crop) => {
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve) => {
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const zoomFactor = imageZoomLevel / 100; // Calculate zoom factor
        const pixelRatio = window.devicePixelRatio;

        const ctx = canvas.getContext("2d");
        canvas.width = crop.width * pixelRatio; // Adjust canvas size for zoom
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
          image,
          crop.x * zoomFactor, // Adjust crop position for zoom
          crop.y * zoomFactor,
          crop.width * zoomFactor,
          crop.height * zoomFactor,
          0,
          0,
          crop.width,
          crop.height
        );

        canvas.toBlob((blob) => {
          const croppedImageUrl = URL.createObjectURL(blob);
          resolve(croppedImageUrl);
        }, "image/jpeg");
      };
    });
  };

  const mergeImagesAndDownload = () => {
    const container = document.getElementById("preview-images-container");

    // Use html2canvas to capture the container's content
    html2canvas(container).then((canvas) => {
      // Crop the canvas to remove white space
      const croppedCanvas = document.createElement("canvas");
      const context = croppedCanvas.getContext("2d");

      // Set the cropped canvas dimensions to match the content width
      croppedCanvas.width = canvas.width;
      croppedCanvas.height = canvas.height;

      // Draw the captured content onto the cropped canvas
      context.drawImage(canvas, 0, 0);

      // Create a data URL for the cropped image
      const mergedImageDataURL = croppedCanvas.toDataURL("image/png");

      // Create a temporary anchor element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = mergedImageDataURL;
      downloadLink.download = "merged_image.png";
      downloadLink.click();
    });
  };

  const moveCropCorner = (direction) => {
    if (!imageRef.current) return;

    // Get the current crop data
    const cropData = imageRef.current.cropper.getData();

    // Modify the crop data based on the direction
    switch (direction) {
      case "up":
        cropData.height += 10;
        cropData.y -= 10; // Increase the height from the top
        break;
      case "down":
        cropData.height -= 10;
        cropData.y += 10; // Increase the height from the bottom
        break;
      case "left":
        cropData.width += 10;
        cropData.x -= 10; // Increase the width from the left
        break;
      case "right":
        cropData.width -= 10;
        cropData.x += 10; // Increase the width from the right
        break;
      default:
        return;
    }

    // Update the crop data
    imageRef.current.cropper.setData(cropData);
  };

  const zoomCrop = (direction) => {
    if (direction === "in") {
      // Zoom In
      if (!imageRef.current) return;
      imageRef.current.cropper.zoom(0.1);
    } else if (direction === "out") {
      // Zoom Out
      if (!imageRef.current) return;
      imageRef.current.cropper.zoom(-0.1);
    }
  };

  const downloadPreviewImage = () => {
    if (croppedImageUrl) {
      const previewCanvas = document.createElement("canvas");
      const previewImage = new Image();

      previewImage.src = croppedImageUrl;

      previewImage.onload = () => {
        previewCanvas.width = previewImage.width;
        previewCanvas.height = previewImage.height;

        const ctx = previewCanvas.getContext("2d");
        applyFilters(ctx, previewImage);

        const previewImageUrl = previewCanvas.toDataURL("image/jpeg");
        const link = document.createElement("a");
        link.href = previewImageUrl;
        link.download = "preview_image_with_filters.jpg";
        link.click();
      };
    }
  };

  const handleAdjustClick = async (channel, adjustmentValue) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("filter", `colorBalance${channel}`);
      formData.append("adjustment", adjustmentValue);

      const response = await fetch(`${apiKey}/manipulate`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Data is >>", data);
        setManipulatedImage(`data:image/jpeg;base64,${data.manipulatedImage}`);
        setOriginalImage(`data:image/jpeg;base64,${data.originalImage}`);
        setSelectedFile(null);
      }
    } else {
      console.log("In else part");
      const blob = dataURLtoBlob(manipulatedImage);
      const imageFile = new File([blob], "output_image.png", {
        type: "image/png",
      });
      const formData = new FormData();
      formData.append("image", imageFile);
      formData.append("filter", channel);
      formData.append("adjustment", adjustmentValue);
      const response = await fetch(`${apiKey}/manipulate`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setManipulatedImage(`data:image/jpeg;base64,${data.manipulatedImage}`);
      }
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const handleIncrement = async (channel) => {
    const currentValue = colorAdjustments[channel.toLowerCase()];
    const updatedValue = currentValue >= 0 ? currentValue + 1 : -currentValue;
    setColorAdjustments((prevAdjustments) => ({
      ...prevAdjustments,
      [channel.toLowerCase()]: updatedValue,
    }));
    const channelName = `colorBalance${channel}`;
    await handleAdjustClick(channelName, updatedValue);
  };

  const handleDecrement = async (channel) => {
    const currentValue = colorAdjustments[channel.toLowerCase()];
    let updatedValue;

    if (currentValue > 0) {
      updatedValue = 0;
    } else {
      updatedValue = currentValue - 1;
    }

    setColorAdjustments((prevAdjustments) => ({
      ...prevAdjustments,
      [channel.toLowerCase()]: updatedValue,
    }));

    const channelName = `colorBalance${channel}`;
    await handleAdjustClick(channelName, updatedValue);
  };

  const applyFilters = (ctx, image) => {
    ctx.filter = `grayscale(${grayscaleValue}%) opacity(${opacityValue}%) sepia(${sepiaValue}%) invert(${invertValue}%)
      contrast(${contrastValue}%) brightness(${brightnessValue}%) saturate(${saturateValue}%) blur(${blurValue}px)`;

    ctx.drawImage(image, 0, 0, image.width, image.height);

    ctx.filter = "none";
  };

  const handleFilterAdjust = async (channel, adjustmentValue) => {
    console.log("In else part", channel, adjustmentValue);
    const blob = dataURLtoBlob(manipulatedImage);
    const imageFile = new File([blob], "output_image.png", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("filter", channel);
    formData.append("adjustment", adjustmentValue);
    const response = await fetch(`${apiKey}/filters`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      setManipulatedImage(`data:image/jpeg;base64,${data.manipulatedImage}`);
    }
  };

  const handleFilterIncrement = async (channel) => {
    console.log("Channel name>>", channel.toLowerCase());
    const currentValue = colorFilter[channel.toLowerCase()];
    const updatedValue =
      currentValue >= 0 ? currentValue + 0.01 : -currentValue;
    setColorFilter((prevAdjustments) => ({
      ...prevAdjustments,
      [channel.toLowerCase()]: updatedValue,
    }));
    await handleFilterAdjust(channel, updatedValue);
  };

  const handleFilterDecrement = async (channel) => {
    const currentValue = colorFilter[channel.toLowerCase()];
    const updatedValue = currentValue - 0.01;

    setColorFilter((prevAdjustments) => ({
      ...prevAdjustments,
      [channel.toLowerCase()]: updatedValue,
    }));

    await handleFilterAdjust(channel, updatedValue);
  };

  const handleFlip = async (angle) => {
    const blob = dataURLtoBlob(manipulatedImage);
    const imageFile = new File([blob], "output_image.png", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("filter", angle);
    const response = await fetch(`${apiKey}/flip`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      setManipulatedImage(`data:image/jpeg;base64,${data.manipulatedImage}`);
    }
  };

  const handleMirror = async (angle) => {
    const blob = dataURLtoBlob(manipulatedImage);
    const imageFile = new File([blob], "output_image.png", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("filter", angle);
    const response = await fetch(`${apiKey}/mirror`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      setManipulatedImage(`data:image/jpeg;base64,${data.manipulatedImage}`);
    }
  };

  const handleRotationChange = (e) => {
    setRotationValue(e.target.value);
  };

  const handleRotateImage = async () => {
    const blob = dataURLtoBlob(manipulatedImage);
    const imageFile = new File([blob], "output_image.png", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("angle", rotationValue);
    const response = await fetch(`${apiKey}/rotate`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      setManipulatedImage(`data:image/jpeg;base64,${data.manipulatedImage}`);
    }
  };

  const handleDownloadClick = () => {
    setDownloadOptionsVisible(true);
  };

  const handleFormatChange = (format) => {
    setDownloadFormat(format);
    if (format == "jpg") {
      setDownloadFormat(format);
      setJpgQualityBox(true);
    } else {
      setDownloadFormat(format);
      setJpgQualityBox(false);
    }
  };

  const handleDownload = async () => {
    // if (manipulatedImage) {
    //   const link = document.createElement("a");
    //   link.href = manipulatedImage;

    //   if (downloadFormat === "jpg") {
    //     link.download = "manipulated_image.jpg";
    //   } else if (downloadFormat === "png") {
    //     link.download = "manipulated_image.png";
    //   }
    //   link.click();
    // }

    const blob = dataURLtoBlob(manipulatedImage);
    const imageFile = new File([blob], "output_image.png", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("image", imageFile);
    if (downloadFormat == "jpg") {
      formData.append("format", downloadFormat);
      formData.append("quality", qualityValue);
    }
    const response = await fetch(`${apiKey}/quality`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      if (data.manipulatedImage) {
        const link = document.createElement("a");
        link.href = `data:image/jpeg;base64,${data.manipulatedImage}`;
        link.download = "manipulated_image.png";
        link.click();
      }
    }
  };

  const resetToDefault = async () => {
    setColorAdjustments({
      red: 0,
      green: 0,
      blue: 0,
    });
    const blob = dataURLtoBlob(originalImage);
    const imageFile = new File([blob], "output_image.png", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("filter", `colorBalanceRed`);
    formData.append("adjustment", 0);

    const response = await fetch(`${apiKey}/manipulate`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Data is >>", data);
      setManipulatedImage(`data:image/jpeg;base64,${data.manipulatedImage}`);
      setOriginalImage(`data:image/jpeg;base64,${data.originalImage}`);
      setSelectedFile(null);
    }
  };

  const handleQualityValue = (e) => {
    setQualityValue(e.target.value);
  };

  const handleColorChange = async (newColor) => {
    setColor(newColor.hex);
    const blob = dataURLtoBlob(manipulatedImage);
    const imageFile = new File([blob], "output_image.png", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("filter", newColor.hex);
    const response = await fetch(`${apiKey}/xor-manipulate`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      setManipulatedImage(`data:image/jpeg;base64,${data.manipulatedImage}`);
    }
  };

  const handleHexColor = (color) => {
    console.log("Color value is >>", color);
    setHexColor(color.hex);
  };

  const handleClickOnImage = async (event) => {
    const image = imageRef.current;
    const rect = image.getBoundingClientRect(); // Get the image's position on the page
    const x = event.clientX - rect.left; // Calculate X coordinate relative to the image
    const y = event.clientY - rect.top; // Calculate Y coordinate relative to the image
    setClickCoordinates({ x, y });
    console.log("cordinate value is >>", clickCoordinates.x);
    const blob = dataURLtoBlob(manipulatedImage);
    const imageFile = new File([blob], "output_image.png", {
      type: "image/png",
    });
    console.log("hex Color value >>", hexColor);
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("coordinates", JSON.stringify(clickCoordinates));
    formData.append("colorCode", hexColor);
    const response = await fetch(`${apiKey}/pixel-color`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      console.log("output is data >>", data);
      setManipulatedImage(`data:image/jpeg;base64,${data.manipulatedImage}`);
    }
  };

  const onCrop = () => {
    if (imageRef.current) {
      const croppedCanvas = imageRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        setCrop({
          x: imageRef.current.cropper.getData().x,
          y: imageRef.current.cropper.getData().y,
          width: imageRef.current.cropper.getData().width,
          height: imageRef.current.cropper.getData().height,
        });
        setCroppedImage(croppedCanvas.toDataURL());
      }
    }
  };

  const handleCrop = () => {
    if (imageRef.current) {
      const croppedCanvas = imageRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        setCrop({
          x: imageRef.current.cropper.getData().x,
          y: imageRef.current.cropper.getData().y,
          width: imageRef.current.cropper.getData().width,
          height: imageRef.current.cropper.getData().height,
        });
        setCroppedImage(croppedCanvas.toDataURL());
      }
    }
  };

  return (
    <div className="App">
      <div className="vertical-section">
        <h2>Image Filter Example</h2>
        <div>
          <div className="row">
            <div className="col-2">
              Brightness
              <div className="button-div">
                <button onClick={() => handleFilterIncrement("Brightness")}>
                  <FaRegCaretSquareUp />
                </button>
                <button onClick={() => handleFilterDecrement("Brightness")}>
                  <FaRegCaretSquareDown />
                </button>
              </div>
            </div>
            <div className="col-2">
              Contrast
              <div className="button-div">
                <button onClick={() => handleFilterIncrement("Contrast")}>
                  <FaRegCaretSquareUp />
                </button>
                <button onClick={() => handleFilterDecrement("Contrast")}>
                  <FaRegCaretSquareDown />
                </button>
              </div>
            </div>
            <div className="col-2">
              GreyScale
              <div className="button-div">
                <button onClick={() => handleFilterIncrement("Greyscale")}>
                  Apply
                </button>
              </div>
            </div>
            <div className="col-2">
              Invert
              <div className="button-div">
                <button onClick={() => handleFilterIncrement("Invert")}>
                  Apply
                </button>
              </div>
            </div>
            <div className="col-2">
              Sepia
              <div className="button-div">
                <button onClick={() => handleFilterIncrement("Sepia")}>
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              Diether16
              <div className="button-div">
                <button onClick={() => handleFilterIncrement("Diether")}>
                  Apply
                </button>
              </div>
            </div>
            <div className="col-2">
              Flip vertically
              <div className="button-div">
                <button onClick={() => handleFlip("Vertical")}>Apply</button>
              </div>
            </div>
            <div className="col-2">
              Flip horizontally
              <div className="button-div">
                <button onClick={() => handleFlip("Horizontal")}>Apply</button>
              </div>
            </div>
            <div className="col-2">
              Mirror Vertically
              <div className="button-div">
                <button onClick={() => handleMirror("Vertical")}>Apply</button>
              </div>
            </div>
            <div className="col-3">
              Mirror Horizontally
              <div className="button-div">
                <button onClick={() => handleMirror("Horizontal")}>
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <label htmlFor="rotationInput">Rotation Angle:</label>
              <input
                type="number"
                id="rotationInput"
                value={rotationValue}
                onChange={handleRotationChange}
              />
              <button onClick={handleRotateImage}>Rotate Image</button>
            </div>
            <div className="col-1">
              Red
              <div className="button-div">
                <button onClick={() => handleIncrement("Red")}>
                  <FaRegCaretSquareUp />
                </button>
                <button onClick={() => handleDecrement("Red")}>
                  <FaRegCaretSquareDown />
                </button>
              </div>
            </div>
            <div className="col-1">
              Green
              <div className="button-div">
                <button onClick={() => handleIncrement("Green")}>
                  <FaRegCaretSquareUp />
                </button>
                <button onClick={() => handleDecrement("Green")}>
                  <FaRegCaretSquareDown />
                </button>
              </div>
            </div>
            <div className="col-1">
              Blue
              <div className="button-div">
                <button onClick={() => handleIncrement("Blue")}>
                  <FaRegCaretSquareUp />
                </button>
                <button onClick={() => handleDecrement("Blue")}>
                  <FaRegCaretSquareDown />
                </button>
              </div>
            </div>
            <div className="col-1">
              Hue
              <div className="button-div">
                <button onClick={() => handleIncrement("Hue")}>
                  <FaRegCaretSquareUp />
                </button>
                <button onClick={() => handleDecrement("Hue")}>
                  <FaRegCaretSquareDown />
                </button>
              </div>
            </div>
            <div className="col-1">
              Lighten
              <div className="button-div">
                <button onClick={() => handleIncrement("Lighten")}>
                  <FaRegCaretSquareUp />
                </button>
                <button onClick={() => handleDecrement("Lighten")}>
                  <FaRegCaretSquareDown />
                </button>
              </div>
            </div>
            <div className="col-2">
              XOR
              <div className="button-div">
                <button onClick={() => setShowColorPicker(true)}>
                  open color picker
                </button>
                {showColorPicker && (
                  <>
                    <ChromePicker color={color} onChange={handleColorChange} />
                    <button onClick={() => setShowColorPicker(false)}>
                      close color picker
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-9">
              {manipulatedImage && (
                <Cropper
                  ref={imageRef}
                  src={manipulatedImage}
                  aspectRatio={16 / 9}
                  guides={true}
                  crop={onCrop}
                />
              )}
            </div>
            <div className="col-3">
              <div className="image-arrow">
                <div className="arrows">
                  <div className="up-down">
                    <button onClick={() => moveCropCorner("up")}>
                      <i className="fa-solid fa-angle-up arrow-icon"></i>
                    </button>
                    <button onClick={() => moveCropCorner("down")}>
                      <i className="fa-solid fa-angle-down arrow-icon"></i>
                    </button>
                  </div>

                  <div className="left-right">
                    <button
                      className="left-arrow"
                      onClick={() => moveCropCorner("left")}
                    >
                      <i className="fa-solid fa-angle-left arrow-icon"></i>
                    </button>
                    <button
                      className="right-arrow"
                      onClick={() => moveCropCorner("right")}
                    >
                      <i
                        className="fa-solid fa-angle-right arrow-icon"
                        style={{ marginLeft: "66px" }}
                      ></i>
                    </button>
                  </div>
                </div>
                <div className="zoom-style">
                  <button onClick={() => zoomCrop("in")}>Zoom In</button>
                  <button onClick={() => zoomCrop("out")}>Zoom Out</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input type="file" accept="image/*" onChange={handleFileChange} />
        <button onClick={() => handleAdjustClick("Red", colorAdjustments.red)}>
          Upload Image
        </button>
        {/* <button onClick={handleCrop}>Crop Image</button> */}
        <div className="container">
          <div className="row">
            <div className="col-8"></div>
          </div>
        </div>
        <div></div>
      </div>

      <div className="vertical-section">
        <div className="container">
          <div className="row">
            {/* merged image */}
            {croppedImage && (
              <div
                className="preview-images-container"
                id="preview-images-container"
              >
                {[...Array(5 * 3)].map((_, index) => (
                  <div key={index} className="preview-image-wrapper">
                    <img
                      ref={mergedCanvasRef}
                      src={croppedImage}
                      alt={`Cropped ${index + 1}`}
                      className="preview-image"
                    />
                  </div>
                ))}
              </div>
            )}
            {/* download and reset button */}
            {croppedImage && (
              <div>
                <div className="container download-style">
                  <div className="row">
                    <div className="col-3">
                      <button onClick={mergeImagesAndDownload}>
                        Merge and Download
                      </button>
                    </div>
                    <div className="col-3">
                      <div className="download-button-container">
                        <button onClick={handleDownloadClick}>
                          Download Image
                        </button>
                        {downloadOptionsVisible && (
                          <div className="download-options">
                            <p>Select options </p>
                            <label>
                              <input
                                type="radio"
                                value="jpg"
                                checked={downloadFormat === "jpg"}
                                onChange={() => handleFormatChange("jpg")}
                              />
                              JPG
                            </label>
                            {jpgQualityBox && (
                              <>
                                <br />
                                <label htmlFor="qualityInput">
                                  Set jpeg Quality:
                                </label>
                                <input
                                  type="number"
                                  id="qualityInput"
                                  onChange={(e) => handleQualityValue(e)}
                                />
                              </>
                            )}
                            <label>
                              <input
                                type="radio"
                                value="png"
                                checked={downloadFormat === "png"}
                                onChange={() => handleFormatChange("png")}
                              />
                              PNG
                            </label>
                            <button onClick={handleDownload}>Download</button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-3">
                      <button onClick={() => resetToDefault()}>
                        Reset Image
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* preview image */}
          <div className="row">
            <div className="col-4">
              {" "}
              {croppedImage && (
                <div className="previewdiv">
                  <h2>Preview:</h2>
                  <img
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      transform: `scale(${imageZoomLevel / 100})`,
                      transformOrigin: "top left",
                    }}
                    src={croppedImage}
                    alt="Crop preview"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
